export default {
  // lang
  vi: "Vietnamese",
  en: "English",
  signin: "Sign In",
  account: "Account",
  password: "Password",
  "forgot password": "Forgot password ?",
  "remember me": "Remember me",
  online: "Online",
  search: "Search",
  result: "Result",
  results: "results",
  general: "General",
  "my profile": "My Profile",
  settings: "Settings",
  help: "Help",
  signout: "Sign Out",
  dashboard: "Dashboard",
  home: "Home",
  project: "Projects",
  "user info": "Hi",
  "projects manager": "Project Management",
  "project list": "Project List",
  "accounts manager": "Accounts Management",
  profile: "Profile",
  "accounts list": "Account list",
  report: "Reports",
  tasks: "Tasks",
  task: "Task",
  "projects worked": "Projects worked on",
  "profile user ": "Account information",
  "profile user update ": "Update information",
  "min ago": "min ago",
  statistic: "Statistic",
  workflow: "Workflow",
  "site-map": "Site map",

  "profile.error.invaliddata": "Fulfill required data before making submission",

  "about us": "About Us",
  contacts: "Contacts",
  404: "PAGE NOT FOUND",

  "settings.title": "Settings",
  "settings.desc": "Application global settings",
  "settings.languages": "Languages",
  "settings.color": "Colors",
  "settings.color button": "Change",
  //status
  initialization: "Initialization",
  implement: "Implement",
  deploy: "Deploy",
  complete: "Complete",
  pause: "Pause",
  //description
  description: "Description",
  "project.info": "Project Information",
  "project.deploy": "Deployment website information",
  //Project
  projects: "Projects",
  "projects.title": "Projects",
  "projects.desc": "Project manager",
  "project_detail.title": "Project detail",
  //List project
  projectcode: "Project Code",
  projectmanager: "Project Manager",
  projectprocess: "Project progress",
  projectmember: "Members",
  projectempty: "No members yet",
  createby: "Create by",
  by: " by ",

  // No privilege alarm

  "alarm.alarm": "Warning",
  "alarm.message": "You have to be the Operator to execute this action",

  "projects.noprojectfound": "No projects can be found!",

  //add project
  addproject: "Add project",
  projectname: "Project name",
  "p.projectname": "Enter a project name",
  projectcode: "Project code",
  "p.projectcode": "Enter the project code",
  projectstatus: "Status",
  projecttype: "Project of",
  projectproxyserver: "API server",
  "p.projectstatus": "Select status",
  projectrole: "Proejct Manager",
  "p.projectrole": "Choose a manager",
  projectdescripton: "Project description",
  "p.projectdescripton": "Enter project description",
  projectmember: "Project members",

  //update project
  updateproject: "Project update",
  //Task
  tasklist: "Tasks",
  detailtask: "Task Detail",
  addtask: "Add Task",
  addtaskchild: "Add Subtask",
  taskname: "Task name",
  "p.taskname": "Enter task name",
  taskstatus: "Status",
  task_priority: "Priority",
  taskmember: "Members",
  "p.description": "Task description",
  "no description": "No description",
  "p.description stage": "Stage description",
  updatestatus: "Status updates",
  viewdetail: "View detail",
  delete: "Delete",
  edit: "Edit",
  edittask: "Edit task",
  deletetask: "Delete Stage",
  editstage: "Edit Stage",
  choose: "Choose",
  duty: "Role",
  confirm: "Confirm",
  await: "Waiting for approval",
  approved: "Approved",

  oldvalue: "Old value",
  newvalue: "New value",
  "time change": "Time for a change",
  "user change": "Changer",

  //Managers
  editmanager: "Edit project manager",
  //Members
  members: "Members",
  //button project
  buttondetail: "View details",
  //error project
  "error.project_name": "Please enter your project name",
  "error.project_status": "Please select project status",

  //Version
  version: "Version",
  versionupdate: "Version update",
  versionname: "Version name",
  "p.versionname": "Enter an version name",

  "p.versiondescription": "Enter a description",

  //User
  "users.title": "Users",
  "users.desc": "Users management",
  //Group user
  administrator: " Administrator",
  uprojectmanager: " Operator",
  normal: " Normal",
  monitor: "Monitor Staff",
  //list
  username: "Username",
  permission: "Permission",
  time: "Time",
  // modal add user,
  "btn.create": "Create new",
  "btn.close": "Close",
  "adduser.title": "Add user",
  "profile.title": "Update profile",
  fullname: "Fullname",
  "p.fullname": "Enter your full name",
  username: "Username",
  "p.username": "Enter your username",
  password: "Password",
  "p.password": "Enter password",
  "re-password": "Confirm password",
  "p.re-password": "Re-enter password",
  email: "Email",
  "p.email": "Enter an email account",
  phone: "Phone number",
  "p.phone": "Enter phone number",
  permission: "Permission",
  "p.permission": "Select permissions",
  address: "Address",
  "p.address": "Enter the address",
  note: "Note",
  "p.note": "Type a note",
  // error user
  "error.fullname": "Please enter your full name",
  "error.username": "Please enter your username",
  "error.password": "Please enter your password",
  "error.confirmpassowrd": "Password mismatch",
  "error.email": "Please enter your email",
  "error.vaildemail": "Invalid email",
  "error.phone": "Please enter your phone number",
  "error.vaildphone": "Invalid phone number",
  "error.permission": "Please select permissions",
  "error.address": "Please enter the address",
  "error.invalidCharacter":
    "Only accept non-accented letters, '-', '_' and '.'",
  "fields default": "Default Value",
  // modal edit user
  "btn.update": "Save",
  "btn.create": "Create new",
  "btn.close": "Close",
  "edituser.title": "Update",
  // logs
  "log.information": "Information",
  "log.warning": "Warning",
  "log.error": "Error",
  "log.title": "Logs",
  "log.statis": "Statistics",
  "log.type": "Type",
  "log.selecttype": "Select type",
  "log.daystart": "Start date",
  "log.dayend": "End date",
  "log.listlog": "Log list",
  "log.no": "No.",
  "log.id": "ID",
  "log.listtitle": "Title",
  "log.dayupdate": "Date",
  "log.action": "Action",
  "btn.ok": "Filter",
  "btn.clear": "Clear",
  "btn.viewdetail": "View details",

  "btn.export": "Export",

  detaillog: "Log details",
  "log.create_user": "Implementer",
  "log.create_at": "Creation date",
  "date execution": "Date of execution",
  "edit fields body": "Edit data fields",
  //home
  "project-status-chart": "Project status chart",
  "project-lead-chart": "Project manager chart",
  "project-status-chart-year": "Project status chart by year",
  "project-number": "Number of projects",
  year: "Year",

  //report
  allstatus: "All status",
  export: "Export",
  //page

  show: "Showing",
  of: "of",
  results: "results",

  "select.member": "Select members",
  //
  supervisor: "Supervisor",
  deployers: "Deployers",
  "btn.cancel": "Cancel",
  "btn.addfield": "Add new field",

  // export options

  "export.title": "Project exporter",
  "export.type": "Export type",

  "export.types.wholeProjects": "Whole project",
  "export.types.tablesOnly": "Database only",
  "export.types.apisOnly": "Apis only",
  "export.types.uiOnly": "UI Only",

  "export.version": "Export version",
  "export.error.invalidData": "Invalid data selection",
  "export.error.invalidVersionData": "Cannot find any UI from this version!",

  "list of tables": "Tables",
  "list of api": "API",
  "list of ui": "UI",
  "create-at": "Creation date",
  "table name": "Table name",
  "table method": "Field method",
  "table conditions": "Conditions",
  "table setDefaultValue": "Default value",
  "api name": "API name",
  "api.description": "Description",
  "ui name": "UI name ",
  creator: "Creator",
  managetable: "Manage Table",
  "create table": "Create new table",
  "list fields": "List of fields",
  key: "Key",
  pkey: "Primary Key",
  fkey: "Foreign Key",
  cascading: "Cascading",
  "fields name": "Fields name",
  datatype: "Data type",
  null: "Data Requests",
  "create fields": "Create new fields",
  "update fields": "Update fields",
  "list add fields": "List of fields to be added",
  //Api
  "manage api": "Manage API",
  "create api": "Create API",
  method: "Method",
  "api name": "API name",
  "select table": "Select table",
  "edit table": "Edit table",
  "selected table": "Selected tables",
  "add param": "Add param fields",
  "edit param": "Edit param fields",
  "param fields": "Param fields",
  "fields display": "Display fields",
  alias: "Aliases",
  "add fields show": "Add fields display",
  "edit fields show": "Edit fields display",
  "calculated fields": "Calculated fields",
  "edit api": "Edit api",
  "fields data": "Body Fields",
  calculations: "Calculations",
  "edit calculated fiels": "Edit calculated field",
  "add fields body": "Add body fields",
  "add fields external body": "Add an extended data field",
  "fields external body": " List of extended data field",
  "rdit fields body": "Edit body fields ",
  "add field calculations": "Add calculated field",
  "edit field calculations": "Edit calculated field",
  fomular: "Fomular",
  "statistical fields": "Statistical fields ",
  "fields name statistic": "Statistics field name",
  "edit statistical fields": "Edit statistics field",
  "edit external fields": "Edit extended data field",
  "select fields": "Select fields",
  "group by": "Group by",
  "ui information": "User interface information",
  "name page": "Page name",
  "manage ui": "Manage user interface",
  "create ui": "Create user interface",
  "ui.title": "Title",

  "add fields statis": "Add statistics field",
  "edit fields statis": "Edit statistics field",
  "fields name statis": "Statistics field name",

  "select fomular": "Select fomular",
  "update statistics fields": "Update statistics fields",
  preview: "Preview",
  //about
  data: "Data",
  "data.content": "Data collection and processing",
  tool: "Tools",
  "tool.content":
    "Provide API creation tool that communicates with other software/systems",
  fast: "Fast",
  "fast.content":
    "Provide information to help users manage, warn errors, and make quick decisions",
  flexible: "Flexible",
  "flexible.content":
    "Simulate customer workflow according to necessary modules",
  "dev-staff": "DEVELOPMENT STAFF",

  "contact.content":
    "Any contributions and questions please send us at any time, we try to answer all inquiries within 24 hours on the working day. It is a pleasure to answer your questions.",
  //notification
  "error.input": "This field can't be blank",
  "error.title": "Fail!",
  "success.title": "Success",

  confirm: "Confirm",
  "delete.api": "Are you sure you want to remove this api?",
  "btn.delete": "Delete",
  "btn.cancel": "Cancel",
  "delete.field": "Are you sure you want to remove this field?",
  "delete.success.field": "The field was successfully deleted",
  "delete.task": "Are you sure you want to remove this task?",
  "delete.member": "Are you sure you want to remove this member?",
  "delete.project": "Are you sure you want to delete this project?",
  "primary-table": "The table must have a primary key",
  "delete.table": "Are you sure you want to delete this table?",
  "error.select.table": "You must select the table!",
  "error.select.field": "You have to choose a field!",
  "error.delete.pramry": "The primary key field must not be deleted.",
  "delete.ui": "Are you sure you want to remove this UI?",
  "delete.user": "Are you sure you want to delete this user?",

  "empty.member": "No members yet",

  "activate.title": "Generate product activation key",
  "activate.mac": "MAC",
  "activate.key": "Key",
  "copy json": "Copy JSON",
  "copy url": "Copy URL",
  high: " High",
  medium: "Medium",
  low: "Low",
  "error.login.empty": "Please complete the information.",
  "preview layout": "Preview layout",
  "not found user": "No users yet",
  "table empty": "The table must not be blank",

  "params empty": "The params should not be left blank",
  "body empty":
    "These fields used to configure the input form must not be left blank",
  "show empty": "Display fields must not flee",
  "not found": "Not found",
  "success.add": "Add success data",
  "success.update": "Update success data",
  "key manager": "Key Management",
  "key manager.title": "Activated projects",

  "project manage implement": "Implementation Project Management",
  activation: "Activation",
  "sitemap.title": "Site map",
  modify_what: "Object changes",
  timeline: "Task Schedule(Gantt Chart)",
  account: "Accounts",
  back: "Back",
  "task.complete": "Task completed",
  "task.expired": "Task is overdue",
  "task.inprogress": "Task needs to be done",
  "read more": "Read more",
  "error.taskname": "Please enter the name of the task",
  "error.start": "Please select a start date",
  "error.end": "Please select an end date",
  "error.checkday_start": "The start date must be less than the end date",
  "error.checkday_end": "The end date must be greater than to the start date",
  "error.task_description": "Please enter a description",
  "error.members": "Please select a member",
  "error.task_priority": " Please choose a priority",
  "error.members_stage": "Please select a member",
  "gantt.year": "Year",
  "gantt.quarters": "Quarters",
  "gantt.months": "Months",
  "gantt.day": "Day",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  listtask: "Tasks",
  "gantt.yearmonths": "Year and Month",
  "create account": "Create Account",
  "error.fk":
    "This field cannot be selected because it is a foreign key and the corresponding primary key exists in the list of fields",
  "error.date":
    "Can't select argument fields with data types of DATE or DECIMAL",
  "edit.project": "Edit project",
  "view.task": "View tasks details",
  "ui.table_statis": "Statistics table template",
  "ui.table": "Template table",
  data: "Data",
  deleteall: "Delete all",
  "api auto": "API Auto",
  "api custom": "API View",
  "all.status": "All status",
  "search.name": "Search by project name...",
  "search.code": "Search by project code...",
  reload: "Reload",
  "title.task": "Task",
  "error.checkday_timeline":
    "The timeline must be within the time period of the task",
  "export task": "Export task list",
  createproject: "Create project",
  avatar: "Avatar",
  copied: "Copied",
  addstage: "New Stage",
  updatestage: "Update Stage",
  stagename: "Stage name",
  "p.stagename": "Enter a stage name",
  "error.stagename": "Please enter a stage name",
  "delete.stage": "Are you sure you want to delete this Stage ?",
  edittaskchild: " Edit subtasks",
  collapse: "Collapse",
  expand: "Expand",
  "no history yet": "No history yet",
  "list group by": "List of selected field (GroupBy)",
  "no selected group by": "No field selected",
  "detail ui": "Project detail",
  next: "Next",
  "add stage": "New Stage",
  "duplicate fomular": "A fields with this fomular alias has already exited",
  "not found task": "The project has no task",
  "hidden-gantt": "Hide Gantt",
  "show-gantt": "Display Gantt",
  "mins ago": "mins ago",
  "just now": "just now",
  "secs ago": "secs ago",
  "hours ago": "hours ago",
  "days ago": "days ago",
  "months ago": "months ago",
  "years ago": "years ago",
  notification: "Notifications",
  "notifi content": "Contents of the notifications",
  "notifi status": "Status",
  "notifi time": "Time to receive notifications",
  unread: "Unread",
  read: "Read",
  "not notification": "Not notifications yet",
  "view all notificaton": "View all Notifications",
  "not found notification": "No notifications found.",
  changepassword: "Change password",
  validPassword: "The password re-entered does not match",
  "new password": "New password",
  "p.new password": "Enter the new password",
  "old password": "Old password",
  "p.old password": "Enter the old password",
  "re password": "Re-enter password",
  "p.re password": "Re-enter the new password",
  change: "Change",
  "wrong password": "Incorrect old password",
  "success.password":
    "Your password has been updated. Please log in again to continue using the service with the new security details",
  "failed.password": "Password change failed",
  "The date must be within the date range of the period":
    "The date must be within the date range of the period",
  "The date must be within the date range of the task":
    "The date must be within the date range of the task",
  "add data": "Add Data",
  "update data": "Update Data",

   // Nhu thêm lang en mới
  
   "Information": "Information",
   "Data" : "Data",
   "Joining": "Joining",
   "Statement " :"Statement",
   "UpdateByCondition" :"UpdateByCondition",
   "Update Fields" : "Update Fields",
   "Field" : "Field",
   "Condition fields": "Condition fields",
   "Chart" : "Chart",
   "Key and Values" : "Key and Values",
   "Key" : "Key",
   "Values" : "Values",
   "Value" : "Value",
 
   //Thinh
    
   "Bảng dữ liệu": "Bảng dữ liệu",
   "Function": "Function",
   "Interface": "Interface",
   // "Tiêu đề": "Ti"
   "Trường tính toán": "Calculated Field",
   "Field name" : "Field name",
   "Search": "Search",
   "Data Joining" : "Data Joining",
   "Data table" :"Data table",
   "Display field": "Display field",
   "Condition":"Condition",
   "Compare" :"Compare",
   "Correct value":"Correct value",
   "Wrong value":"Wrong value",
   "Alias":"Alias",
   "Return value":"Return value",
   "select value":"select value",
   "Board":"Board",
    "Left operand":"Left operand",
   "Right Operand":"Right Operand",
   "Prerequisites":"Prerequisites",
   "Position":"Position",
   "Comparative value":"Comparative value",
   "Comparison method":"Comparison method",
    "Area" : "Area",
    "Enter from 1 to 12" : "Enter from 1 to 12"
};
