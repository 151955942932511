import { useEffect, useState } from "react";
import Select from "react-select";
import functions from "../../../../../redux/configs/functions";
export const SearchContainer = ({
  options,
  MAPPED_FIELDS,
  onChange,
  default_value = [],
}) => {
  const [fields, setFields] = useState(default_value);
  const { getFormatedUUID } = functions;

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const handleChange =
    ({ type, payload }) =>
    () => {
      switch (type) {
        case "add":
          setFields((prev) => [{ id: getFormatedUUID() }, ...prev]);
          break;
        case "update":
          setFields((prev) => {
            const newFields = [...prev];
            const index = newFields.findIndex(
              (field) => field.id === payload.id
            );

            if (index > -1) {
              for (const k in payload) {
                newFields[index][k] = payload[k];
              }
            }

            return newFields;
          });
          break;
        case "delete":
          setFields((prev) => prev.filter(({ id }) => id !== payload.id));
          break;
        default:
          break;
      }
    };

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  return (
    <section>
      <button
        className="btn btn-success"
        onClick={handleChange({ type: "add" })}
      >
        Add search field
      </button>
      {fields.map(({ id, label, value, isSelect, isRegexSearching }) => (
        <section key={id}>
          <button
            className="btn btn-danger mt-2"
            onClick={handleChange({ type: "delete", payload: { id } })}
          >
            Delete
          </button>
          <div style={{ zIndex: "1" }}>
            <Select
              className="mt-2"
              styles={customStyles}
              options={options}
              value={{ label, value }}
              onChange={(option) => {
                let alias = option.label
                  ?.split("-$")
                  ?.at(-1)
                  ?.split(".")
                  ?.at(-1);
                alias = alias[0] === "$" ? alias.slice(1) : alias;
                const field = MAPPED_FIELDS.find(
                  ({ fomular_alias }) => fomular_alias === alias
                );

                if (field) {
                  handleChange({
                    type: "update",
                    payload: { id, field, ...option },
                  })();
                }
              }}
            />
          </div>
          <div className="mt-2">
            <label>IsSelect</label>
            <input
              type="checkbox"
              checked={isSelect}
              onChange={({ target: { checked } }) => {
                handleChange({
                  type: "update",
                  payload: { id, isSelect: checked },
                })();
              }}
            />
          </div>
          <section>
            <label>IsRegexSearching</label>
            <input
              type="checkbox"
              checked={isRegexSearching}
              onChange={({ target: { checked } }) => {
                handleChange({
                  type: "update",
                  payload: { id, isRegexSearching: checked },
                })();
              }}
            />
          </section>
        </section>
      ))}
    </section>
  );
};
