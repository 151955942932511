export default (state, action) => {
    switch (action.type) {

        case "alertFire":

            break;
        default:
            return state;
    }
}
