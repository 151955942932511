import React, { useState } from "react";
import { Conditions } from "../../../../../Components/Conditions";
import ColorContext from "../../../../api/ContextTable";
import { useSelector } from "react-redux";

export const ConditionsComponent = (
  props = {
    getPropByPath: () => [],
    updateSelectedComponent: () => [],
    selectedCpn: {},
    path: "",
  }
) => {
  const { getPropByPath, updateSelectedComponent, path, selectedCpn, id } =
    props;

  const [color, setColor] = useState({});
  const { tables } = useSelector((s) => s);

  return (
    <ColorContext.Provider value={{ color, setColor }}>
      <Conditions
        onChange={({ field_id, payload }) => {
          updateSelectedComponent(payload, path.split("."));
        }}
        conditions={getPropByPath(path.split("."), selectedCpn)}
        field_id={""}
        fields={tables}
        tables={tables}
      />
    </ColorContext.Provider>
  );
};
