import { memo, useEffect, useMemo, useState, useId } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import functions from "../../../../../redux/configs/functions";
const CHARTS = {
  table_chart: 1,
  c_chart: 1,
};

function Component(props) {
  const { page, tables: root_tables, lang } = useSelector((s) => s);
  const { getFormatedUUID } = functions;

  const charts =
    page.component.reduce((prev, component) => {
      if (CHARTS[component.name]) {
        prev.push(component);
      } else if (component.name === "grid") {
        const list_chart = component.children.filter(
          (component) => CHARTS[component.name]
        );
        prev.push(...list_chart);
      }
      return prev;
    }, []) || [];

  const { updateSelectedComponent, path, selectedCpn } = props;
  const initials_tables = selectedCpn?.props?.tables || [];
  const initial_charts = selectedCpn?.props?.charts || [];
  const initial_selected_fields = selectedCpn?.props?.Selected_Fields || [];

  const [state, setState] = useState(initials_tables);
  const [selectedCharts, setSelectedCharts] = useState(initial_charts);
  const [joinedTables, setJoinedTables] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [Selected_Fields, setSelected_Fields] = useState(
    initial_selected_fields
  );
  console.log("Selected_Fields", Selected_Fields);
  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };
  const handleShowFields = () => {
    setShowFields(!showFields);
  };

  useEffect(() => {
    setJoinedTables((prev) => {
      let newSelectedCharts = [...selectedCharts];
      const joinedTables_mapped = [];

      for (const k in charts) {
        const {
          joiningTable: { tables },
        } = charts[k].props;

        if (newSelectedCharts.find((chart) => chart.value === charts[k].id)) {
          for (const i in tables) {
            const left_table =
              tables[i].alias["left_table"] || tables[i].left_table;
            const right_table =
              tables[i].alias["right_table"] || tables[i].right_table;

            const key = `${left_table}-${right_table}`;

            if (!joinedTables_mapped.find((item) => item.key === key)) {
              let left_fields = [];
              let right_fields = [];

              for (const index in root_tables) {
                if (root_tables[index].table_alias === tables[i].left_table) {
                  left_fields = root_tables[index].fields;
                }
                if (root_tables[index].table_alias === tables[i].right_table) {
                  right_fields = root_tables[index].fields;
                }
              }

              joinedTables_mapped.push({
                key,
                tables: {
                  [left_table]: left_fields,
                  [right_table]: right_fields,
                },
              });
            }
          }
        }
      }
      return joinedTables_mapped;
    });
  }, [selectedCharts]);

  useEffect(() => {
    setState((prev) => {
      return prev.filter((s) =>
        joinedTables.find((table) => table.key === s.key)
      );
    });
  }, [joinedTables]);

  useEffect(() => {
    updateSelectedComponent(Selected_Fields, ["props", "Selected_Fields"]);
  }, [Selected_Fields]);

  useEffect(() => {
    updateSelectedComponent(selectedCharts, ["props", "charts"]);
    updateSelectedComponent(state, ["props", "tables"]);
  }, [selectedCharts, state]);

  useEffect(() => {
    setState(initials_tables);
    setSelectedCharts(initial_charts);
  }, [selectedCpn]);

  const handleAddField = () => {
    const newField = { id: getFormatedUUID(), reference_field: {}, field: {} };
    // setSelected_Fields([newField])
    setSelected_Fields((prev) => [newField, ...prev]);
  };

  const handleDelField = (id) => {
    setSelected_Fields((prev) => prev.filter((field) => field.id !== id));
  };

  const handleFieldChange = (id, data, type) => {
    if(type === "reference_field"){
      setSelected_Fields((prev) =>
      prev.map(({ id: _id, ...props }) => {
        if (_id === id) {
          props.reference_field = data;
        }
        return { id: _id, ...props };
      })
    );
    }else{
      setSelected_Fields((prev) =>
      prev.map(({ id: _id, ...props }) => {
        if (_id === id) {
          return { id: _id, ...props, field: data };
        }
        return { id: _id, ...props };
      })
    );
    }
    
  };
  const handleReferenceFieldChange = (id, data) => {
    
  };
  return (
    <div className="padding-1rem">
      <div className="c-chart p-0">
        <div
          className={` ${showSearch ? "chart-header pb-2" : "chart-header-of"}`}
          onClick={handleShowSearch}
        >
          <div className="chart-label">
            <FontAwesomeIcon
              icon={showSearch ? faAngleUp : faAngleRight}
              className="me-2"
            />
            {lang["Search"]}
          </div>
        </div>
        {showSearch && (
          <section>
            <div className="p-2">
              <Select
                value={selectedCharts}
                isMulti
                name="colors"
                onChange={(charts) => {
                  setSelectedCharts(charts);
                }}
                options={charts.map(({ props: { content }, id }) => ({
                  label: content,
                  value: id,
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
            <div className="joining-container p-0">
              <div
                // className="joining-header"
                className={`${
                  showFields ? "joining-header" : " joining-header-of"
                }`}
                onClick={handleShowFields}
              >
                <div className="chart-label m-2">
                  <FontAwesomeIcon
                    icon={showFields ? faAngleUp : faAngleRight}
                    className="me-2"
                  />
                  {lang["Field"]}
                </div>
              </div>
              {showFields && (
                <div className="p-1">
                  <div className="accordion" id="accordionJoining">
                    {joinedTables.map(({ key, tables }, i) => (
                      <div className="p-1">
                        <div
                          className="accordion-item accordion-item-chart"
                          key={i}
                        >
                          <h2
                            className="accordion-header "
                            id={`panelsStayOpen-heading${i}`}
                          >
                            <button
                              className="accordion-button accordion-button-left"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapseLinkCharts-${i}`}
                              aria-expanded={i === 0 ? "true" : "false"}
                              aria-controls={`panelsStayOpen-collapseLinkCharts-${key}`}
                              type="button"
                            >
                              {key}
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapseLinkCharts-${i}`}
                            className={`accordion-collapse collapse ${
                              i === 0 ? "show" : ""
                            }`}
                            aria-labelledby={`panelsStayOpen-heading${i}`}
                          >
                            <div className="accordion-body p-2">
                              {(() => {
                                const children = [];
                                for (const k in tables) {
                                  const fields = tables[k];
                                  children.push(
                                    <section>
                                      <label>{k}</label>
                                      <Select
                                        isMulti
                                        name="colors"
                                        value={
                                          state
                                            .find((item) => key === item.key)
                                            ?.tables[k]?.map(
                                              ({
                                                fomular_alias,
                                                field_name,
                                              }) => ({
                                                label: field_name,
                                                value: fomular_alias,
                                              })
                                            ) || []
                                        }
                                        onChange={(data) => {
                                          setState((prev) => {
                                            const index = prev.findIndex(
                                              (item) => item.key === key
                                            );

                                            const fields_mapped = fields.filter(
                                              (field) =>
                                                data.find(
                                                  ({ value }) =>
                                                    value ===
                                                    field.fomular_alias
                                                )
                                            );

                                            if (index > -1) {
                                              prev[index].tables[k] =
                                                fields_mapped;
                                            } else {
                                              prev.push({
                                                key,
                                                tables: { [k]: fields_mapped },
                                              });
                                            }
                                            return [...prev];
                                          });
                                        }}
                                        options={fields.map(
                                          ({ field_name, fomular_alias }) => ({
                                            label: field_name,
                                            value: fomular_alias,
                                          })
                                        )}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </section>
                                  );
                                }
                                return children;
                              })()}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {
                <div className="p-2">
                  <label>Trường select:</label>
                  <button
                    className="btn btn-success ml-2 mb-2"
                    onClick={handleAddField}
                  >
                    Thêm
                  </button>
                  {Selected_Fields.map(({ reference_field, field, id }) => {
                    return (
                      <section
                        key={id}
                        className="c-chart p-2"
                        style={{ border: "1px grey solid" }}
                      >
                        <label>Field</label>
                        <Select
                          name="colors"
                          onChange={(data) => {
                            handleFieldChange(id, data, "field");
                          }}
                          value={field}
                          options={(() => {
                            const options = [];
                            state?.map(({ tables }) => {
                              for (const k in tables) {
                                const list = tables[k];
                                for (const index in list) {
                                  const { field_name, fomular_alias } =
                                    list[index];
                                  options.push({
                                    label: field_name,
                                    value: fomular_alias,
                                    field: list[index],
                                  });
                                }
                              }
                            });

                            return options;
                          })()}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <label>Reference Field</label>
                        <Select
                          name="colors"
                          onChange={(data) => {
                            handleFieldChange(id, data, "reference_field");
                          }}
                          value={reference_field}
                          options={(() => {
                            const options = [];
                            root_tables?.map((table) => {
                              const { table_alias, fields, table_name } = table;

                              for (const index in fields) {
                                const { field_name, fomular_alias } =
                                  fields[index];

                                options.push({
                                  label: `${field_name}-${table_alias}-${table_name}`,
                                  value: fomular_alias,
                                  field: fields[index],
                                });
                              }
                            });
                            return options;
                          })()}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <button
                          className="btn btn-danger mt-2"
                          onClick={() => {
                            handleDelField(id);
                          }}
                        >
                          Xóa
                        </button>
                      </section>
                    );
                  })}
                </div>
              }
            </div>
          </section>
        )}
      </div>
    </div>
  );
}
export const LinkCharts = memo(Component);
