export default {
  // lang
  vi: "Tiếng Việt",
  en: "English",
  signin: "Đăng nhập",
  account: "Tên đăng nhập",
  password: "Mật khẩu",
  "forgot password": "Quên mật khẩu ?",
  "remember me": "Ghi nhớ đăng nhập",
  online: "Trực tuyến",
  general: "Tổng quan",
  search: "Tìm kiếm",
  result: "Kết quả",
  results: "kết quả",
  "my profile": "Hồ sơ của tôi",
  settings: "Cấu hình",
  help: "Trợ giúp",
  signout: "Đăng xuất",
  dashboard: "Bảng điều khiển",
  home: "Trang chủ",
  "user info": "Xin chào",
  project: "Dự án",
  "projects manager": "Quản lý dự án",
  "project list": "Danh sách dự án",
  "accounts manager": "Quản lý tài khoản",
  "accounts list": "Danh sách tài khoản",
  profile: "Thông tin cá nhân",
  "profile user": "Thông tin tài khoản",
  "profile user update": "Cập nhật thông tin cá nhân",
  "min ago": "phút trước",
  report: "Báo cáo",
  tasks: "Yêu cầu",
  task: "Yêu cầu",
  "projects worked": "Dự án đã tham gia",
  statistic: "Thống kê",
  workflow: "Quy trình",
  "site-map": "Site map",
  "about us": "Giới thiệu",
  contacts: "Liên hệ",
  404: "KHÔNG TÌM THẤY",
  "settings.title": "Cấu hình",
  "settings.desc": "Cấu hình chung cho ứng dụng",
  "settings.languages": "Ngôn ngữ",
  "settings.color": "Màu sắc",
  "settings.color button": "Thay đổi",

  oldvalue: "Giá trị cũ",
  newvalue: "Giá trị mới",
  "time change": "Thời gian thay đổi",
  "user chagne": "Người thay đổi",

  "profile.error.invaliddata": "Vui lòng điền đầy đủ thông tin",
  //description
  description: "Mô tả",

  "project.info": "Thông tin dự án",
  "project.deploy": "Thông tin website triển khai",
  //Project
  projects: "Dự án",
  "projects.title": "Quản lý dự án",
  "project_detail.title": "Chi tiết dự án",
  //status
  initialization: "Khởi tạo",
  implement: "Thực hiện",
  deploy: "Triển khai",
  complete: "Hoàn thành",
  pause: "Tạm dừng",
  //List project
  projectcode: "Mã dự án",
  projectmanager: "Quản lý dự án",
  projectprocess: "Tiến độ dự án",
  projectmember: "Thành viên",
  projectempty: "Chưa có thành viên",
  createby: "Tạo bởi",
  by: " bởi ",

  // No privilege alarm

  "alarm.alarm": "Cảnh báo",
  "alarm.message": "Bạn phải có quyền Quản trị viên để thực thi hành động này",

  "projects.noprojectfound": "Không có dự án nào được tìm thấy ",

  //add project
  addproject: "Thêm mới dự án",
  createproject: "Tạo dự án",

  projectname: "Tên dự án",
  "p.projectname": "Nhập tên dự án",
  projectcode: "Mã dự án",
  "p.projectcode": "Nhập mã dự án",
  projectstatus: "Trạng thái",
  projecttype: "Kiểu dự án",
  projectproxyserver: "Tên miền API",
  "p.projectstatus": "Chọn trạng thái",
  projectrole: "Người quản lý",
  "p.projectrole": "Chọn người quản lý",
  projectdescripton: "Mô tả dự án",
  "p.projectdescripton": "Nhập mô tả dự án",
  projectmember: "Thành viên dự án",
  //update project
  updateproject: "Cập nhật dự án",

  //Task
  "title.task": "Công việc",
  tasklist: "Danh sách các công việc",
  detailtask: "Chi tiết công việc",
  addtask: "Thêm công việc",
  addtaskchild: "Thêm công việc con",
  taskname: "Tên công việc",
  "p.taskname": "Nhập tên công việc",
  taskstatus: "Trạng thái",
  task_priority: "Mức độ ưu tiên",
  taskmember: "Thành viên",
  "p.description": "Mô tả công việc",
  "p.description stage": "Mô tả giai đoạn",
  updatestatus: "Cập nhật trạng thái",
  "no description": "Không có mô tả",
  viewdetail: "Xem chi tiết",
  delete: "Xóa",
  edit: "Chỉnh sửa",
  edittask: "Chỉnh sửa công việc",
  editstage: "Chỉnh sửa giai đoạn",
  deletetask: "Xóa giai đoạn",
  await: "Chờ xác nhận",
  approved: "Đã xác nhận",
  choose: "Chọn",
  "user change": "Người thay đổi",
  //Managers
  editmanager: "Chỉnh sửa quản lý dự án",
  //Members
  members: "Thành viên",
  //button project
  buttondetail: "Xem chi tiết",
  // error project
  "error.project_name": "Vui lòng nhập tên dự án",
  "error.project_status": "Vui lòng chọn trạng thái dự án",

  //Version
  version: "Phiên bản",
  versionupdate: "Cập nhật phiên bản",
  versionname: "Tên phiên bản",
  "p.versionname": "Nhập tên phiên bản",
  "p.versiondescription": "Nhập mô tả",
  //Task
  task: "Yêu cầu",
  duty: "Chức vụ",

  "users.title": "Quản lý người dùng",
  //Group user
  administrator: " Quản trị viên",
  uprojectmanager: " Vận hành",
  normal: " Người dùng",
  monitor: "Người theo dõi dự án",
  //list user
  username: "Tên đăng nhập",
  permission: "Quyền",
  time: "Thời gian",
  // modal add user
  "btn.create": "Thêm mới",
  "btn.close": "Đóng",
  "adduser.title": "Thêm người dùng",
  "profile.title": "Cập nhật hồ sơ",
  fullname: "Họ tên",
  "p.fullname": "Nhập đầy đủ họ tên",
  username: "Tên đăng nhập",
  "p.username": "Nhập tên đăng nhập",
  password: "Mật khẩu",
  "p.password": "Nhập mật khẩu",
  "re-password": "Nhập lại mật khẩu",
  "p.re-password": "Nhập lại mật khẩu",
  email: "Email",
  "p.email": "Nhập tài khoản email",
  phone: "Số điện thoại",
  "p.phone": "Nhập số điện thoại",
  permission: "Quyền",
  "p.permission": "Chọn quyền",
  address: "Địa chỉ",
  "p.address": "Nhập địa chỉ",
  note: "Ghi chú",
  "p.note": "Nhập ghi chú",

  //Error user
  "error.fullname": "Vui lòng nhập đầy đủ họ tên",
  "error.username": "Vui lòng nhập tên đăng nhập",
  "error.password": "Vui lòng nhập mật khẩu",
  "error.confirmpassowrd": "Mật khẩu không khớp",
  "error.email": "Vui lòng nhập email",
  "error.validemail": "Email không hợp lệ",
  "error.phone": "Vui lòng nhập số điện thoại",
  "error.vaildphone": "Số điện thoại không hợp lệ",
  "error.permission": "Vui lòng chọn quyền",
  "error.address": "Vui lòng nhập địa chỉ",

  "select.member": "Chọn thành viên",
  // button modal edit user
  "btn.update": "Lưu lại",
  "edituser.title": "Chỉnh sửa người dùng",

  // logs
  "log.information": "Thông tin",
  "log.warning": "Cảnh báo",
  "log.error": "Lỗi",
  "log.title": "Lịch sử",
  "log.statis": "Thống kê",
  "log.type": "Loại",
  "log.selecttype": "Chọn loại",
  "log.daystart": "Ngày bắt đầu",
  "log.dayend": "Ngày kết thúc",
  "log.listlog": "Danh sách lịch sử",
  "log.no": "STT",
  "log.id": "ID",
  "log.listtitle": "Tiêu đề",
  "log.dayupdate": "Ngày cập nhật",
  "log.action": "Thao tác",
  "btn.ok": "Lọc",
  "btn.clear": "Xóa",
  "btn.viewdetail": "Xem chi tiết",
  "btn.export": "Xuất",
  detaillog: "Chi tiết lịch sử",
  "log.create_user": "Người thực hiện",
  "log.create_at": "Ngày tạo",
  "date execution": "Ngày thực hiện",
  //home
  "project-status-chart": "Biểu đồ trạng thái dự án",
  "project-lead-chart": "Biểu đồ người quản lý dự án",
  "project-status-chart-year": "Biểu đồ trạng thái dự án theo năm",
  "project-number": "Số dự án",
  year: "Năm",
  //report
  allstatus: "Tất cả trạng thái",
  export: "Xuất",
  //page

  show: "Hiển thị",
  of: "của",
  results: "kết quả",

  supervisor: "Giám sát",
  deployers: "Triển khai",

  "btn.cancel": "Hủy",
  "btn.addfield": "Thêm mới trường",

  // export oprions

  "export.title": "Xuất dự án",
  "export.type": "Loại xuất bản",

  "export.types.wholeProjects": "Toàn bộ dự án",
  "export.types.tablesOnly": "Cơ sở dữ liệu",
  "export.types.apisOnly": "API",
  "export.types.uiOnly": "UI",

  "export.version": "Chọn phiên bản",
  "export.error.invalidData":
    "Các trường cần phải được điền dữ liệu trước khi xuất",
  "export.error.invalidVersionData":
    "Không tìm thấy UI nào trong Phiên bản này!",

  "list of tables": "Danh sách bảng",

  "list of api": "Danh sách API",
  "list of ui": "Danh sách UI",
  "create-at": "Ngày tạo",
  "table name": "Tên bảng",
  "table method": "Phương thức",
  "table conditions": "Điều kiện",
  "table setDefaultValue": "Giá trị mặc định",
  "api name": "Tên API",
  "ui name": "Tên UI ",
  creator: "Người tạo",
  managetable: "Quản lý bảng",
  "create table": "Tạo bảng mới",
  "list fields": "Danh sách các trường",
  key: "Khóa",
  pkey: "Khóa chính",
  fkey: "Khóa ngoại",
  cascading: "Xoá hàng loạt",
  "fields name": "Tên trường",
  datatype: "Kiểu dữ liệu",
  null: "Yêu cầu dữ liệu",

  "create fields": "Tạo trường mới",
  "update fields": "Cập nhật trường",
  "list add fields": "Danh sách các trường chuẩn bị thêm vào",
  //api
  "manage api": "Quản lý API",
  "create api": "Tạo API",

  method: "Phương thức",
  "api name": "Tên API",
  "api.description": "Mô tả API",
  "select table": "Chọn bảng",
  "edit table": "Chỉnh sửa bảng",
  "selected table": "Danh sách các bảng đã chọn",
  "add param": "Thêm trường đối số",
  "edit param": "Chỉnh sửa trường đối số",
  "param fields": "Danh sách các trường đối số",

  "fields display": "Danh sách các trường hiển thị",
  alias: "Bí danh",
  "add fields show": "Thêm trường hiển thị",
  "edit fields show": "Chỉnh sửa trường hiển thị",
  "calculated fields": "Danh sách các trường tính toán",

  calculations: "Phép tính",
  "add fields body": "Thêm trường dữ liệu",
  "add fields external body": "Thêm trường dữ liệu mở rộng",
  "fields external body": "Danh sách trường dữ liệu mở rộng",
  "edit fields body": "Chỉnh sửa trường dữ liệu",
  "add field calculations": "Thêm trường tính toán",
  "edit field calculations": "Chỉnh sửa trường tính toán",
  fomular: "Phép toán",
  "fields name statistic": "Tên trường thống kê",
  "statistical fields": "Danh sách trường thống kê",
  "edit statistical fields": "Chỉnh sửa trường thống kê",
  "edit external fields": "Chỉnh sửa trường mở rộng",
  "select fields": "Chọn trường",
  "group by": "Nhóm giá trị bởi trường",
  "edit api": "Chỉnh sửa api",
  "fields data": "Danh sách các trường dữ liệu",

  //ui
  "manage ui": "Quản lý giao diện",
  "ui information": "Thông tin giao diện",
  "name page": "Tên trang",
  "create ui": "Tạo mới giao diện",
  "ui.title": "Tiêu đề",
  "add fields statis": "Thêm trường thống kê",
  "fields name statis": "Tên trường thống kê",
  "select fomular": "Chọn công thức",
  "update statistics fields": "Cập nhật trường thống kê",
  preview: "Xem trước",

  //about
  data: " Dữ liệu",
  "data.content": "Thu thập và xử lý dữ liệu",
  tool: "Công cụ",
  "tool.content":
    "Cung cấp công cụ tạo API giao tiếp với phần mềm / hệ thống khác",
  fast: "Nhanh chóng",
  "fast.content":
    "Cung cấp thông tin hỗ trợ người dùng quản lý, cảnh báo lỗi và đưa ra quyết định nhanh chóng",
  flexible: "Linh hoạt",
  "flexible.content":
    "Mô phỏng quy trình làm việc của khách hàng theo các module cần thiết",
  "dev-staff": "ĐỘI PHÁT TRIỂN",

  // contact
  "contact.content":
    "Mọi đóng góp và câu hỏi vui lòng gửi cho chúng tôi bất kỳ lúc nào, chúng tôi cố gắng trả lời tất cả các câu hỏi trong vòng 24 giờ vào ngày làm việc. Rất hân hạnh được trả lời câu hỏi của bạn.",
  //notificatio
  "error.input": "Trường này không được để trống",
  "error.title": "Thất bại!",
  "success.title": "Thành công",

  confirm: "Xác nhận",
  "delete.api": "Bạn có chắc chắn muốn xóa api này?",
  "btn.delete": "Xóa",

  "delete.field": "Bạn có chắc chắn muốn xóa trường này?",
  "delete.success.field": "Trường đã được xóa thành công",
  "delete.task": "Bạn có chắc chắn muốn xóa yều cầu này?",
  "delete.member": "Bạn có chắc chắn muốn xóa thành viên này?",
  "delete.project": "Bạn có chắc chắn muốn xóa dự án này?",
  "primary-table": "Bảng phải có khóa chính",
  "delete.table": "Bạn có chắc chắn muốn xóa bảng này?",
  "error.select.table": "Bạn phải chọn bảng",
  "error.select.field": "Bạn phải chọn trường",
  "error.delete.pramry": "Không được xóa trường khóa chính.",
  "delete.ui": "Bạn có chắc chắn muốn xóa UI này?",
  "delete.user": "Bạn có chắc chắn muốn xóa người dùng này?",
  validPassword: "Mật khẩu nhập lại không khớp",

  "empty.member": "Chưa có thành viên",

  "not found field": "Chưa có trường",

  "activate.title": "Tạo khóa kích hoạt cho dự án",
  "activate.mac": "MAC",
  "activate.key": "Khóa kích hoạt",
  "copy json": "Sao chép JSON",
  "copy url": "Sao chép URL",
  high: " Cao",
  medium: "Trung bình",
  low: "Thấp",
  "error.login.empty": "Vui lòng nhập đầy đủ thông tin",
  "preview layout": "Xem trước bố cục",
  "not found user": "Chưa có người dùng",
  "table empty": "Bảng này không được bỏ trống",
  "params empty": "Đối số không được để trống",

  "body empty":
    "Các trường này dùng để cấu hình biểu mẫu nhập liệu không được để trống",
  "show empty": "Các trường hiển thị không được bỏ trống",
  "not found": "Không tìm thấy",

  "success.add": "Thêm mới thành công",
  "success.update": "Cập nhật thành công",
  "key manager": "Quản lý khóa kích hoạt",
  "key manager.title": "Danh sách các dự án đã kích hoạt",
  "project manage implement": "Quản lý dự án triển khai",
  activation: "Kích hoạt",
  "sitemap.title": "Sơ đồ website",
  modify_what: "Đối tượng thay đổi",
  timeline: "Lịch trình công việc",
  account: "Tài khoản",
  back: "Quay về",
  "task.complete": "Task đã hoàn thành",
  "task.expired": "Task đang trễ",
  "task.inprogress": "Task cần thực hiện",
  "read more": "Xem thêm",
  "error.taskname": "Vui lòng nhập tên yêu cầu công việc",
  "error.start": "Vui lòng chọn ngày bắt đầu",
  "error.end": "Vui lòng chọn ngày kết thúc",
  "error.checkday_start": "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
  "error.checkday_end": "Ngày kết thúc phải lớn hơn ngày bắt đầu",
  "error.task_description": "Vui lòng nhập mô tả cho yêu cầu công việc",
  "error.members": "Vui lòng chọn thành viên",
  "error.task_priority": " Vui lòng chọn mức độ ưu tiên",
  "error.members_stage": "Vui lòng chọn người thực hiện giai đoạn",
  "gantt.year": "Năm",
  "gantt.quarters": "Quý",
  "gantt.months": "Tháng",
  "gantt.day": "Ngày",
  january: "Tháng 1",
  february: "Tháng 2",
  march: "Tháng 3",
  april: "Tháng 4",
  may: "Tháng 5",
  june: "Tháng 6",
  july: "Tháng 7",
  august: "Tháng 8",
  september: "Tháng 9",
  october: "Tháng 10",
  november: "Tháng 11",
  december: "Tháng 12",
  listtask: "Danh sách công việc",
  "gantt.yearmonths": "Năm và tháng",
  "error.invalidCharacter": "Chỉ chấp nhận chữ không dấu, '-', '_' và '.'",
  "fields default": "Giá trị mặc định",
  "create account": "Tạo tài khoản",
  "error.fk":
    "Không thể chọn trường này vì nó là khóa ngoại và khóa chính tương ứng tồn tại trong danh sách các trường",
  "error.date":
    "Không thể chọn trường đối số có kiểu dữ liệu là DATE hoặc DECIMAL",
  "edit.project": "Chỉnh sửa dự án",
  "view.task": "Xem chi tiết công việc",
  "ui.table": "Bảng mẫu",
  "ui.table_statis": "Mẫu bảng thống kê",
  data: "Dữ liệu",
  deleteall: "Xóa tất cả",
  "api auto": "API tự động",
  "api custom": "API View",
  "all.status": "Tất cả trạng thái",
  "search.name": "Tìm theo tên dự án...",
  "search.code": "Tìm theo mã dự án...",
  reload: "Tải lại",
  "error.checkday_timeline":
    "Timeline phải nằm trong khoảng thời gian của công việc",
  "export task": "Xuất danh sách công việc",
  avatar: "Ảnh đại diện",
  copied: "Đã sao chép",
  addstage: "Thêm mới giai đoạn",
  updatestage: "Cập nhật giai đoạn",
  stagename: "Tên giai đoạn",
  "p.stagename": "Nhập tên giai đoạn",
  "error.stagename": "Vui lòng nhập tên giai đoạn",
  "delete.stage": "Bạn có chắc chắn muốn xóa giai đoạn này?",
  edittaskchild: "Chỉnh sửa công việc con",
  collapse: "Thu gọn",
  expand: "Mở rộng",
  "no history yet": "Chưa có lịch sử",
  "list group by": "Danh sách các trường được chọn (GroupBy)",
  "no selected group by": "Không có trường nào được chọn",
  "detail ui": "Chi tiết dự án",
  next: "Tiếp tục",
  "add stage": "Thêm giai đoạn",
  "duplicate fomular": "Trường với bí danh này đã tồn tại",
  "not found task": "Dự án không có yêu cầu",
  "hidden-gantt": "Ẩn Gantt",
  "show-gantt": "Hiển thị Gantt",
  "just now": "Vừa xong",
  "mins ago": "phút trước",
  "secs ago": "giây trước",
  "hours ago": "giờ trước",
  "days ago": "ngày trước",
  "months ago": "tháng trước",
  "years ago": "năm trước",
  notification: "Thông báo",
  "notifi content": "Nội dung thông báo",
  "notifi status": "Trạng thái",
  "notifi time": "Thời gian nhận thông báo",
  unread: "Chưa đọc",
  read: "Đã đọc",
  "not notification": "Chưa có thông báo",
  "view all notificaton": "Xem tất cả thông báo",
  "not found notification": "Không tìm thấy thông báo",
  changepassword: "Đổi mật khẩu",

  "change password": "Thay đổi mật khẩu",
  "p.new password": "Nhập mật khẩu mới",
  "new password": "Mật khẩu mới",
  "p.old password": "Nhập mật khẩu cũ",
  "old password": "Mật khẩu cũ",
  "p.re password": "Nhập lại mật khẩu mới",
  "re password": "Nhập lại mật khẩu",
  change: "Thay đổi",

  "wrong password": "Mật khẩu cũ không chính xác",
  "success.password":
    "Mật khẩu của bạn đã được cập nhật. Xin hãy đăng nhập lại để tiếp tục sử dụng dịch vụ với thông tin bảo mật mới ",
  "failed.password": "Thay đổi mật khẩu thất bại",
  "The date must be within the date range of the period":
    "Ngày phải nằm trong phạm vi ngày của Giai đoạn",

  "The date must be within the date range of the task":
    "Ngày phải nằm trong phạm vi ngày của công việc",

  "add data": "Thêm dữ liệu",
  "update data": "Cập nhật dữ liệu",

  
  // Nhu thêm lang vi mới
  "Information" : "Thông Tin",
  "Bảng dữ liệu": "Bảng dữ liệu",
  "Function": "Chức năng",
  "Interface": "Giao diện",
  "Xem trước": "Xem trước",
  "Data" : "Dữ Liệu",
  "Data Joining" : "Dữ liệu liên kết",
  "Joining": "Liên kết",
  "Statement": "Câu lệnh",
  "UpdateByCondition" : "Cập nhật theo điều kiện",
  "Update Fields" : "Cập nhật trường",
  "Field" : "Trường",
  "Condition fields": "Trường điều kiện",
  "Chart" : "Biểu đồ",
  "Key and Values" : "khóa và giá trị",
  "Key" : "khóa",
  "Values" : "Giá trị",
  "Field name" : "Tên trường",
  "Value" : "Giá trị",
  "Search": "Tìm Kiếm",
  "Data table" :"Bảng Dữ liệu",
  "Display field" : "Trường hiển thị",
  "Results" :" Kết quả" ,
  "Condition": "Điều kiện",
  "Compare":"So sánh",
  "Correct value":"Giá trị đúng",
  "Wrong value":"Giá trị sai",
  "Alias":"Bí danh",
  "Return value":"Giá trị trả về",
  "Board":"Bảng",
  "Left operand":"Toán hạng bên trái",
  "Right Operand":"Toán hạng bên phải",
  "Prerequisites": "Điều kiện cần",
  "Left fields":"Trường bên trái",
  "Right fields":"Trường bên phải",
  "Position" :"Định vị",
  "Comparative value":"Giá trị so sánh",
  "Comparison method":"Phương thức so sánh ",
  "select value" :"Chọn giá trị",
  "Area" : "Vùng",
  "Enter from 1 to 12": "Nhập từ 1 đến 12"
};
