import { memo } from "react";

function OrderBy({
  groups: { fields = [] } = {},
  onChange,
  defaultValue = [],
  label = "Order by",
}) {
  return (
    <section>
      <div className="label-box mt-2 mb-2">{label}</div>
      <div class="form-check">
        {fields.map(({ id, field_name, fomular_alias }) => {
          const id_reference = Math.random();
          const item = defaultValue.find(
            (item) => item.field === fomular_alias
          );
          const checked = item ? true : false;
          return (
            <section key={id}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id={id_reference}
                checked={checked}
                onChange={({ target: { checked } }) => {
                  onChange(checked, fomular_alias);
                }}
              />
              <label class="form-check-label" for={id_reference}>
                {field_name}-{fomular_alias}
              </label>
              <select
                onChange={({ target: { value } }) => {
                  onChange(checked, fomular_alias, value);
                }}
              >
                <option disabled selected></option>
                <option value="asc" selected={item?.type === "asc"}>
                  ASC
                </option>
                <option value="desc" selected={item?.type === "desc"}>
                  DESC
                </option>
              </select>
            </section>
          );
        })}
      </div>
    </section>
  );
}
export const OrderByContainer = memo(OrderBy);
